<template>
	<div class="otp-page">
		<div class="content">
			<b-alert v-if="error" show variant="danger">Error when joining room - {{error}}</b-alert>
			<template v-else>
				<fa-icon v-if="isprocessing" icon="spinner" spin show />
				<template v-else>
					<div class="logo">
						<img src="../assets/images/home-logo.png" alt="logo" />
					</div>
					<div class="otp-card">
						<p>Please type your verification code to get into the room.</p>
						<input type="text" class="otp-box" id="otp" v-model.trim="OTP" />
						<button class="gold-button" @click="joinUsingOTP">Enter</button>
					</div>
				</template>
			</template>
		</div>
	</div>
</template>
<script>
	import { mapState, mapActions } from 'vuex'
	import DEBUG from '@/common/DEBUG'

	export default {
		name: 'otp',
		components: {
		},

		data() {
			return {
				OTP: null,
				isprocessing: false,
				error: null,
				connection: null,
			}
		},

		async mounted() {
			const that = this;
			const route = that.$route
			that.OTP = route.query.OTP;
			if (that.OTP) {
				that.$nextTick(async () => await that.joinUsingOTP());
			}
		},

		computed: {
			...mapState(["userinfo", "token"]),
		},

		methods: {
			...mapActions(["getGame", "joinGame", "getGameDefinition"]),

			async joinUsingOTP() {
				const that = this
				const OTP = that.OTP;
				if (!OTP) {
					that.notifyError(`please specify a valid OTP and try again`)
					return
				}

				that.isprocessing = true;
				try {
					const playerid = that.userinfo.id
					const game = await that.joinGame({ OTP, playerid });
					DEBUG.log('JOINING GAME - %o', game)

					const { id } = game;
					await that.$router.push({ name: 'play', params: { id } })
					await that.notifySuccess(`OTP accepted navigating to game room...`)
					that.error = null;
				}
				catch (error) {
					that.error = `unable to use OTP, please try again - ${error.message}`
				}
				finally {
					that.isprocessing = false;
				}
			}
		},
	}
</script>